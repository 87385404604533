import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      // minWidth: 200,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  })
);

export default HtmlTooltip;
