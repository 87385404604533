import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  CircularProgress,
} from '../mui';
import axios from '../utils/axios';
import Snackbar from '../components/snackbar';
import Scrollbar from '../components/scrollbar/Scrollbar';

const inviterTypes = [
  { id: 1, title: 'Admin', enum: 'ADMIN' },
  { id: 2, title: 'Member', enum: 'MEMBER' },
];

const emailInitialState = ['', '', '', '', ''];

const InviteDialog = ({ openDialog, setOpenDialog }) => {
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [invitationType, setInvitationType] = useState('MEMBER');
  const [emails, setEmails] = useState(emailInitialState);
  const [snackAlert, setSnackAlert] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const handleClose = () => {
    setOpenDialog(null);
    setSnackAlert(null);
    setIsValid(false);
    setSuccess(false);
  };

  const emailInputHandler = (value, index) => {
    setEmails((prevArr) => {
      const newArr = [...prevArr]; // create a new array from the previous state
      newArr[index] = value; // update the value at the specified index
      return newArr; // return the new array as the updated state
    });
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emails.filter((str) => str !== '').every((str) => emailRegex.test(str))) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [emails]);

  const invitationHandler = async () => {
    setLoader(true);
    setSnackAlert(null);
    const filteredArray = emails.filter((str) => str.trim() !== '');
    try {
      const payload = { emails: filteredArray, invitation_type: invitationType };
      const { data: response } = await axios.post('/invite_members', payload);
      if (response.status) {
        setSuccess(true);
        setSnackAlert({ severity: 'success', message: response.message });
      } else {
        setSnackAlert({ severity: 'error', message: response.message });
      }
    } catch (err) {
      console.log('err', err);
      setSnackAlert({ severity: 'error', message: 'Something went wrong !' });
    } finally {
      setEmails(emailInitialState);
      setLoader(false);
      setIsValid(false);
    }
  };

  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm" PaperProps={{ sx: { height: '65%' } }}>
      {snackAlert && <Snackbar severity={snackAlert.severity} message={snackAlert.message} />}
      <DialogTitle id="alert-dialog-title">Invite your team to AccentAssist </DialogTitle>
      <DialogContent>
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
          }}
        >
          {loader ? (
            <Box display="flex" justifyContent="center" alignItems="center" mt={20}>
              <CircularProgress />
            </Box>
          ) : success ? (
            <Stack justifyContent="center" alignItems="center" sx={{ minHeight: '100%' }}>
              <Typography fontWeight="bold">Invite Sent!</Typography>
              <img src="/assets/email.jpg" alt="Success" style={{ width: '100%', height: '280px' }} />
            </Stack>
          ) : (
            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} px={2} py={2}>
              <Typography>To :</Typography>
              {Array.from({ length: 5 }, (_, index) => (
                <TextField
                  type="email"
                  key={index}
                  placeholder="abc@gmail.com"
                  size="small"
                  value={emails[index]}
                  fullWidth
                  onChange={(e) => emailInputHandler(e.target.value.trim(), index)}
                />
              ))}
              <Typography>Invite as :</Typography>
              <FormControl fullWidth>
                <Select value={invitationType} onChange={(e) => setInvitationType(e.target.value)}>
                  {inviterTypes.map((inviter, index) => (
                    <MenuItem value={inviter.enum} key={index}>
                      {inviter.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
        </Scrollbar>
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        {/* <Button onClick={handleClose}>{success ? 'Close' : 'Cancel'}</Button> */}
        {!success && (
          <Button
            variant="contained"
            onClick={invitationHandler}
            autoFocus
            disabled={!isValid || !emails.filter((str) => str !== '').length}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InviteDialog;
