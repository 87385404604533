import { useFilePicker } from 'use-file-picker';
import { Button, Box, Typography, Grid } from '../../mui';

// const lightInput = {
//   borderColor: '#a4a4a4',
//   mt: 3,
//   borderRadius: '10px',
//   marginTop: '10px',
//   boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
//   backgroundColor: '#ffffff',
// };

// const darkInput = {
//   borderColor: '#a4a4a4',
//   mt: 3,
//   borderRadius: '10px',
//   marginTop: '10px',
//   boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
//   backgroundColor: '#282828',
// };

const FilePicker = ({ file, setFile, title, dimensions, handleRemoveFile }) => {
  const [openFileSelector, { filesContent, loading }] = useFilePicker({
    accept: '.png',
    multiple: false,
    readAs: 'DataURL',
    onFilesSelected: ({ plainFiles }) => {
      setFile({
        image: plainFiles[0],
        name: plainFiles[0]?.name,
        upload: true,
        url: URL.createObjectURL(plainFiles[0]),
      });
    },
  });

  const removeFile = () => {
    setFile(null);
    if (handleRemoveFile) handleRemoveFile();
  };

  if (loading) {
    return (
      <Typography varient="body2" sx={{ px: '8px', py: '6px' }}>
        Loading...
      </Typography>
    );
  }

  return (
    <Box sx={{ bgcolor: 'rgba(124, 124, 124, 0.1)', p: 2, borderRadius: 2 }}>
      <Typography varient="body2" sx={{ py: '6px' }}>
        {title}
      </Typography>
      {/* style={localStorage.getItem('theme') === 'DARK' ? darkInput : lightInput} */}
      <Box>
        <Grid container>
          <Grid item xs={5}>
            <Button
              varient="contained"
              color="primary"
              onClick={() => {
                if (file) removeFile();
                openFileSelector();
              }}
            >
              Select file
            </Button>
          </Grid>
          <Grid item xs={7}>
            <Typography
              varient="body2"
              sx={{
                px: '8px',
                py: '6px',
                height: '33px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                wordWrap: 'break-word',
              }}
            >
              {file && filesContent?.length ? filesContent[0]?.name : file?.name ?? `No file selected`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography
        component="h5"
        sx={{
          py: '6px',
          fontSize: '0.7rem',
        }}
      >
        Note: To avoid distortion, please upload the image with the correct dimensions. ({dimensions})
      </Typography>
      {file?.url ? (
        <Box
          component="img"
          sx={{ mt: 2, height: '50px', borderRadius: '20px', objectFit: 'contain', width: '100%' }}
          alt={filesContent.length ? filesContent[0]?.name : file ? file?.name : null}
          src={filesContent.length ? filesContent[0]?.content : file ? file?.url : null}
        />
      ) : (
        <Box
          component="img"
          sx={{ mt: 2, height: '50px', borderRadius: '20px', objectFit: 'contain', width: '100%' }}
          alt="Dummy avatar"
          src="/assets/illustrations/illustration_avatar.png"
        />
      )}
    </Box>
  );
};

export default FilePicker;
