import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ExamplesStack, LearnsEnum } from '../utils/helpers';
import { Box, Stack, Typography } from '../mui';
import Scrollbar from '../components/scrollbar';
import { StyledToggleButtonGroup } from '../components/toggle-buttons/styles';

export default function LearnsToggleSection({ setOpened, opened, category, setCategory }) {
  const [expand, setExpand] = useState(false);
  return (
    <Box sx={{ height: '100%' }}>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              p: '5px 10px',
              borderRadius: 2,
              m: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.125rem', textAlign: 'start', width: '100%' }} mb={3} px={'6px'}>
              Learn
            </Typography>
            <StyledToggleButtonGroup
              style={
                opened === LearnsEnum.examples && !category
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => {
                setOpened(LearnsEnum.examples);
                setExpand((prev) => !prev);
                setCategory(null);
              }}
              endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              Example Prompts
            </StyledToggleButtonGroup>
            {opened === LearnsEnum.examples && expand && (
              <Box sx={{ width: '90%', paddingBottom: 1 }}>
                {ExamplesStack?.map((item, index) => (
                  <StyledToggleButtonGroup
                    key={index}
                    style={
                      category === item.value
                        ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                        : { marginTop: 4 }
                    }
                    onClick={() => setCategory(item.value)}
                  >
                    {item.label}
                  </StyledToggleButtonGroup>
                ))}
              </Box>
            )}
            <StyledToggleButtonGroup
              style={
                opened === LearnsEnum.builder
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => {
                setOpened(LearnsEnum.builder);
                setCategory(null);
                setExpand(false);
              }}
            >
              Prompt Builder
            </StyledToggleButtonGroup>
          </Stack>
        </>
      </Scrollbar>
    </Box>
  );
}
