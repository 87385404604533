import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Divider, Typography, Grid, Button, AddIcon, Menu, MenuItem, Box, CircularProgress } from '../mui';
import CustomToolDialogue from './CustomToolDialog';
import CustomCategoryDialog from './CustomCategoryDialog';
import {
  deleteCustomToolById,
  getAllTools,
  handleSystemToolsAction,
  handleSystemToolsUpdate,
  updateToolsPreority,
} from './apiCalls';
import {
  setActiveRerender,
  setCategoriesTools,
  setCustomTools,
  setSelectedPrompt,
  setSystemTools,
} from '../redux/slices/MasterSlice';
import SystemToolsComp from '../components/tool/SystemTools';
import CustomToolsComp from '../components/tool/CustomTools';

const ToolPage = ({ category }) => {
    const [hoverFieldId, setHoverFieldId] = useState(null);
  const [selectedField, setSelectedField] = useState({
    id: '',
    deleteId: '',
  });
  const [systemField, setSystemField] = useState(null);
  const [editFieldId, setEditFieldId] = useState(null);

  const [editedTitle, setEditedTitle] = useState('');
  const [editedField, setEditedField] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [dltBtnLoader, setDltBtnLoader] = useState(false);
  const [customToolId, setCustomToolId] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const systemTools = useSelector((state) => state.Master.systemTools);
  const settingsRerender = useSelector((state) => state.Master.settingsRerender);
  const search = JSON.parse(localStorage.getItem('search'));
  const [toolsData, setToolsData] = useState([]);
  
  const filteredTools = useMemo(() => {
    const filteredItems =
      category?.type === 'custom'
        ? toolsData.filter(
            (tool) => tool?.tool_type === 'CUSTOMTOOL' && tool?.custom_tools_category_id === category?.id
          )
        : category?.type === 'system'
        ? toolsData.filter((tool) => tool?.tool_type === 'SYSTEMTOOL')
        : toolsData;
    // const sortedItems = [...filteredItems].sort((a, b) => a?.priority - b?.priority);
    return filteredItems;
  }, [toolsData, category]);

  const dispatch = useDispatch();
  const isAdministrator = useSelector((state) => state.settings.isAdministrator);


  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const response = await dispatch(getAllTools(controller.signal));
      if (response) {
        const sortedItems = [...response?.systemTools, ...response?.customTools].sort(
          (a, b) => a?.priority - b?.priority
        );
        setToolsData(sortedItems);
        dispatch(setSystemTools(response?.systemTools));
        dispatch(setCustomTools(response?.customTools));
        dispatch(setCategoriesTools(response?.categories));
      }
      setLoader(false);
    })();
    return () => controller.abort();
  }, [dispatch, settingsRerender]);

  const handleToggle = async (id, action) => {
    // optimistic rendering for Redux State
    dispatch(
      setSystemTools(
        systemTools.map((item) => {
          if (item.id === id) {
            return { ...item, is_disabled: !item.is_disabled };
          }
          return item;
        })
      )
    );
    // Local State
    setToolsData((tools) =>
      tools.map((tool) => {
        if (tool.tool_type === 'SYSTEMTOOL' && tool.id === id) {
          return { ...tool, is_disabled: !tool.is_disabled };
        }
        return tool;
      })
    );
    const response = await dispatch(handleSystemToolsAction(id, !action));
    if (response) {
      // For Search Title
      if (search.id === id) {
        dispatch(setSelectedPrompt(null));
        localStorage.removeItem('search');
      }
    }
  };

  const handleNameChange = async (id) => {
    setBtnLoader(true);
    try {
      const payload = {
        key: editedTitle,
      };
      const response = await dispatch(handleSystemToolsUpdate(id, payload));
      if (response) {
        // optimistic rendering for Redux State
        dispatch(
          setSystemTools(
            systemTools.map((item) => {
              if (item.id === id) {
                return { ...item, name: editedTitle };
              }
              return item;
            })
          )
        );
        // Local State
        setToolsData((tools) =>
          tools.map((tool) => {
            if (tool.tool_type === 'SYSTEMTOOL' && tool.id === id) {
              return { ...tool, name: editedTitle };
            }
            return tool;
          })
        );
        // For Search Title
        if (search.id === id) dispatch(setSelectedPrompt(null));
      }
      setEditFieldId(null);
      setBtnLoader(false);
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const handleDescChange = async (id) => {
    setBtnLoader(true);
    try {
      const payload = {
        [editedField?.key]: editedField?.value ? editedField?.value : null,
      };
      const response = await dispatch(handleSystemToolsUpdate(id, payload));
      if (response) {
        // optimistic rendering
        dispatch(
          setSystemTools(
            systemTools.map((item) => {
              if (item.id === id) {
                return { ...item, [editedField?.key]: editedField?.value };
              }
              return item;
            })
          )
        );
        // Local State
        setToolsData((tools) =>
          tools.map((tool) => {
            if (tool.tool_type === 'SYSTEMTOOL' && tool.id === id) {
              return { ...tool, [editedField?.key]: editedField?.value };
            }
            return tool;
          })
        );
      }
      setBtnLoader(false);
      setEditedField(null);
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const editPersonality = async (e, id) => {
    e.stopPropagation();
    setCustomToolId(id);
     };

  const deletePersonality = async (e, id) => {
    e.stopPropagation();
    setDltBtnLoader(true);
    const response = await dispatch(deleteCustomToolById(id));
    if (response) {
      dispatch(setActiveRerender());
      if (search.id === id) {
        dispatch(setSelectedPrompt(null));
        localStorage.removeItem('search');
      }
      // Local State
      setToolsData((tools) =>
        tools.filter((tool) => tool.tool_type !== 'CUSTOMTOOL' || (tool.tool_type === 'CUSTOMTOOL' && tool.id !== id))
      );
    }
    setDltBtnLoader(false);
  };

  useEffect(() => {
    if (customToolId) setOpen(true);
  }, [customToolId]);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleAddTools = () => {
    setOpen(true);
    setOpenCategory(false);
    handleMenuClose();
  };

  const handleAddCategory = () => {
    setOpen(false);
    setOpenCategory(true);
    handleMenuClose();
  };

  const updatePriority = (orignalArray, filteredArray, startIndex, endIndex) => {
    if (startIndex === endIndex) {
      // No changes are needed if the startIndex and endIndex are the same.
      return orignalArray;
    }
    const itemToMove = filteredArray[startIndex];
    const targetItem = filteredArray[endIndex];

    const prevIndex = orignalArray.findIndex(
      (a) => a?.search_type === itemToMove?.search_type && a?.id === itemToMove?.id
    );
    const newIndex = orignalArray.findIndex(
      (a) => a?.search_type === targetItem?.search_type && a?.id === targetItem?.id
    );

    const newArray = cloneDeep(orignalArray);
    const movedItem = newArray.splice(prevIndex, 1)[0];
    newArray.splice(newIndex, 0, movedItem);

    const start = prevIndex < newIndex ? prevIndex : newIndex;
    const end = prevIndex > newIndex ? prevIndex : newIndex;

    for (let i = start; i <= end; i += 1) {
      newArray[i].priority = orignalArray[i].priority;
    }

    return newArray;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reOrderedItems = updatePriority(toolsData, filteredTools, result.source.index, result.destination.index);
    setToolsData(reOrderedItems);
    const payload = {
      indexdata: reOrderedItems.map((tool) => ({
        tool_id: tool.id,
        tool_type: tool.tool_type,
        priority: tool.priority,
      })),
    };
    await dispatch(updateToolsPreority(payload));
    dispatch(setActiveRerender());
  };

  return !loader ? (
    <Grid container justifyContent="center" alignItems="center" spacing={3} my={4} mb={12}>
      <Grid item xs={10}>
        <Typography component="span" variant="h6">
          Assistants
        </Typography>
       {isAdministrator && <Button variant="contained" sx={{ float: 'right' }} onClick={handleMenuOpen} endIcon={<AddIcon />}>
          Add
        </Button>}
        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleAddTools}>Add Assistant</MenuItem>
          <MenuItem onClick={handleAddCategory}>Add Category</MenuItem>
        </Menu>
      </Grid>
      <Grid item xs={10}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {filteredTools.map((tool, index) => (
                  <Draggable
                    key={tool.id}
                    draggableId={tool?.tool_type === 'SYSTEMTOOL' ? `TOOL-${tool.id}` : `CUSTOMTOOL-${tool.id}`}
                    index={index}
                    isDragDisabled={!isAdministrator}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {tool?.tool_type === 'SYSTEMTOOL' ? (
                          <SystemToolsComp
                            tool={tool}
                            btnLoader={btnLoader}
                            editFieldId={editFieldId}
                            editedTitle={editedTitle}
                            editedField={editedField}
                            systemField={systemField}
                            setEditedTitle={setEditedTitle}
                            setEditFieldId={setEditFieldId}
                            setEditedField={setEditedField}
                            setSystemField={setSystemField}
                            handleToggle={handleToggle}
                            handleNameChange={handleNameChange}
                            handleDescChange={handleDescChange}
                          />
                        ) : (
                          <CustomToolsComp
                            tool={tool}
                            hoverFieldId={hoverFieldId}
                            dltBtnLoader={dltBtnLoader}
                            editPersonality={editPersonality}
                            deletePersonality={deletePersonality}
                            selectedField={selectedField}
                            setSelectedField={setSelectedField}
                            setHoverFieldId={setHoverFieldId}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>

      <Divider sx={{ my: 2 }} />
      {open && (
        <CustomToolDialogue
          category={category?.type === 'custom' ? category.id : ''}
          customToolId={customToolId}
          setCustomToolId={setCustomToolId}
          openDialog={open}
          setOpenDialog={setOpen}
        />
      )}
      {openCategory && <CustomCategoryDialog openDialog={openCategory} setOpenDialog={setOpenCategory} />}
    </Grid>
  ) : (
    <Box display="flex" justifyContent="center" my={20}>
      <CircularProgress />
    </Box>
  );
};

export default ToolPage;
