export const promptsJSON = [
  {
    category: 'marketing',
    title: 'Write a white paper',
    description:
      'This prompt guides you in writing a white paper that establishes your business as a thought leader in your industry.',
    prompt:
      'Write a white paper that explains the benefits of digital transformation for traditional industries. Discuss the challenges faced in the process, case studies of successful transformation, and a guide on how companies can navigate their digital transformation journey.',
    ideas:
      'Tailor the content to your industry and product offerings. Include expert opinions, data, and unique research. Add visuals such as infographics and diagrams. End with a call-to-action encouraging readers to contact your company for a consultation or to use your product/service.',
  },
  {
    category: 'marketing',
    title: 'Create a webinar script',
    description: 'This prompt helps you generate a script for a webinar on a topic relevant to your industry.',
    prompt:
      "Create a script for a one-hour webinar titled 'Leveraging Big Data for Marketing Success'. The webinar should cover the basics of big data, how it can be applied in marketing, real-life examples of successful usage, and a Q&A session at the end.",
    ideas:
      "Customize the script to match your brand's voice and cater to your audience's knowledge level. Include interactive elements such as polls or quizzes. Incorporate time for audience questions. End the webinar with a call-to-action, like signing up for a free trial of your product or downloading a related whitepaper.",
  },
  {
    category: 'marketing',
    title: 'Design a product launch plan',
    description: 'This prompt guides you in creating a comprehensive plan for a B2B product launch.',
    prompt:
      "Design a product launch plan for a new SaaS product targeted at SMEs. The plan should include pre-launch activities, launch day activities, and post-launch follow-up. Highlight how you'll generate buzz, engage your audience, and measure success.",
    ideas:
      'Modify the plan based on your product features, target audience, and market conditions. Include activities such as press releases, email marketing, social media promotion, webinars, and influencer partnerships. Set clear KPIs to measure the success of the launch. Post-launch, plan for user feedback collection and engagement activities to keep the momentum going.',
  },
  {
    category: 'marketing',
    title: 'Optimize a landing page',
    description: 'This prompt assists you in optimizing a landing page to improve conversions.',
    prompt:
      'Review and suggest improvements for a landing page promoting a new eBook. Focus on the page layout, call-to-action, copy, visuals, and overall user experience. The goal is to increase eBook downloads and capture more leads.',
    ideas:
      'Tailor your suggestions based on your brand guidelines and target audience preferences. Consider SEO optimization and mobile-friendly design. Experiment with different headlines, visuals, and CTA placements. Use heatmaps or A/B testing to inform changes.',
  },
  {
    category: 'marketing',
    title: 'Plan a content strategy',
    description: 'This prompt helps you develop a content strategy to engage your audience and drive conversions.',
    prompt:
      'Create a three-month content strategy for a B2B company selling cloud solutions. The strategy should include different types of content - blog posts, whitepapers, social media posts, newsletters - and a plan for promoting them. It should also include key topics to focus on, frequency of posting, and KPIs to measure success.',
    ideas:
      "Adjust the plan based on your company's goals, resources, and audience. Diversify your content to cater to different audience segments. Plan for regular content audits to assess performance and make necessary adjustments. Coordinate with sales and product teams to align your content with product launches or important events.",
  },
  {
    category: 'marketing',
    title: 'Craft an engaging newsletter',
    description:
      'This prompt will help you generate an enticing newsletter to effectively communicate with your B2B audience. It can inspire you with fresh content ideas for your newsletters.',
    prompt:
      "Create a monthly newsletter for B2B marketers. It should include industry news, recent developments in digital marketing techniques, tips for effective B2B marketing strategies, and an insightful case study. Don't forget to add a personal touch with a note from the CEO, a customer success story, and a preview of upcoming events or webinars.",
    ideas:
      "Tailor the given prompt to resonate with your brand voice and cater to your specific audience's interests. Modify the section topics based on what your audience engages with the most. Feature not only your own products or services, but also broader industry trends, thought leadership pieces, or guest contributions. Incorporate visuals and infographics for better engagement. Conclude each newsletter with a clear call to action, such as inviting your audience to an upcoming webinar, or guiding them to a new blog post or whitepaper on your website.",
  },
  {
    category: 'sales',
    title: 'Develop a prospecting email',
    description:
      'This prompt guides you in creating an effective prospecting email to generate interest from potential clients.',
    prompt:
      'Craft a prospecting email for a potential client in the manufacturing sector. The email should introduce your company, describe the value of your product/service, and propose a meeting or call to discuss their needs further.',
    ideas:
      "Personalize the email based on the recipient's industry and possible challenges. Keep the email concise and direct. Use compelling subject lines and ensure the call to action is clear.",
  },
  {
    category: 'sales',
    title: 'Prepare a sales presentation',
    description:
      "This prompt assists you in preparing a persuasive sales presentation to showcase your product's value.",
    prompt:
      "Create a sales presentation for a B2B SaaS product. The presentation should explain the product's features, its advantages over competitors, how it solves the customer's problems, and include a compelling call to action.",
    ideas:
      'Tailor the presentation to your audience, addressing their specific pain points and business goals. Use visuals to highlight key points. Incorporate testimonials or case studies for credibility. End with a clear next step for the audience.',
  },
  {
    category: 'sales',
    title: 'Script a product demo',
    description:
      'This prompt helps you create a script for a product demo that effectively showcases your product to a potential client.',
    prompt:
      'Write a script for a 30-minute product demo of a project management tool aimed at mid-sized tech companies. The demo should walk through key features, show how the product can streamline their processes, and end with a Q&A session.',
    ideas:
      "Make the script interactive and tailored to the prospect's business needs. Use real-life scenarios for the demo. Be prepared to handle objections and questions. Schedule a follow-up after the demo to gauge interest and answer further questions.",
  },
  {
    category: 'sales',
    title: 'Write a follow-up email',
    description: 'This prompt guides you in writing a follow-up email after a sales meeting or product demo.',
    prompt:
      'Craft a follow-up email to a potential client after a product demo. The email should summarize the key points from the demo, address any concerns raised, offer further assistance or information, and propose next steps.',
    ideas:
      "Personalize the email based on the client's feedback and concerns. Keep the email positive and proactive. Propose a specific next step, like another meeting, a proposal, or a trial period.",
  },
  {
    category: 'sales',
    title: 'Craft a negotiation strategy',
    description: 'This prompt assists you in developing a negotiation strategy for closing a complex B2B sales deal.',
    prompt:
      "Outline a negotiation strategy for a high-stakes sales deal with a large corporation. Discuss how to handle potential objections, what concessions you're willing to make, how to demonstrate value, and how to steer the conversation towards agreement.",
    ideas:
      "Keep the strategy flexible and prepared for different scenarios. Understand the client's needs and priorities thoroughly. Consider including alternative solutions or extras that don't cost you much but provide value to the client. Maintain a positive, cooperative tone.",
  },
  {
    category: 'customerSuccess',
    title: 'Create a customer onboarding plan',
    description:
      'This prompt guides you in creating a thorough onboarding plan for new customers, ensuring they understand and can effectively use your software.',
    prompt:
      'Design a customer onboarding plan for new users of our project management software. The plan should cover initial setup, key features walkthrough, training sessions, resources for self-help, and a schedule for follow-up calls or emails.',
    ideas:
      "Tailor the plan based on your customer's specific needs and tech savviness. Keep the onboarding process engaging and straightforward. Consider different learning styles and provide resources in various formats - videos, articles, webinars. Set clear expectations and deliverables for each step of the onboarding process.",
  },
  {
    category: 'customerSuccess',
    title: 'Write a check-in email',
    description:
      'This prompt assists you in writing a check-in email to gauge customer satisfaction and address potential issues.',
    prompt:
      'Write a check-in email for a customer who has been using our software for three months. The email should inquire about their user experience, address any issues or concerns they may have, and offer assistance to help them get more value from the software.',
    ideas:
      "Personalize the email based on the customer's usage patterns and feedback. Maintain a friendly and supportive tone. Offer specific tips for maximizing the use of the software. Reiterate your availability and willingness to assist them.",
  },
  {
    category: 'customerSuccess',
    title: 'Plan a customer success strategy',
    description:
      'This prompt helps you develop a comprehensive customer success strategy to increase customer satisfaction and retention.',
    prompt:
      'Create a 12-month customer success strategy to increase customer satisfaction and reduce churn. The strategy should include onboarding, training, regular check-ins, feedback collection and analysis, escalation handling, and renewal process.',
    ideas:
      "Align the strategy with your company's business objectives. Use customer feedback and data to inform the strategy. Consider various customer segments and tailor the approach accordingly. Regularly review and adjust the strategy based on its performance.",
  },
  {
    category: 'customerSuccess',
    title: 'Prepare a customer training session',
    description:
      'This prompt guides you in preparing a customer training session to educate customers about using your software effectively.',
    prompt:
      'Prepare a one-hour training session for customers on how to use the advanced features of our software. The session should include a walkthrough of the features, practical examples, tips and tricks, and a Q&A section.',
    ideas:
      "Keep the session interactive and engaging. Use real-life scenarios or case studies. Provide a session recap or handout for future reference. Record the session for those who can't attend live and for future use.",
  },
  {
    category: 'customerSuccess',
    title: 'Craft a software update announcement',
    description:
      'This prompt assists you in crafting an announcement for a software update, communicating the new features and improvements to the customers.',
    prompt:
      'Craft an announcement for a major update of our software. The announcement should explain the new features, improvements over the previous version, and instructions on how to update the software. It should also express appreciation for customer feedback that led to these improvements.',
  },
];
