import { forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import { TreeItem, useTreeItem } from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import { Grid } from '@mui/material';

const CustomContent = forwardRef((props, ref) => {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const { disabled, expanded, selected, focused, handleExpansion } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  return (
    <Grid
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      ref={ref}
    >
      <Grid onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </Grid>
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
    </Grid>
  );
});

const CustomTreeItem = forwardRef((props, ref) => <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />);

export default CustomTreeItem;
