import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from 'react-redux';

import {
  Typography,
  Switch,
  Stack,
  EditRoundedIcon,
  TextField,
  IconButton,
  CheckIcon,
  CircularProgress,
  Box,
  CloseIcon,
} from '../../mui';

const SystemTools = ({
  tool,
  btnLoader,
  editFieldId,
  editedTitle,
  editedField,
  setEditedTitle,
  setEditFieldId,
  setEditedField,
  systemField,
  setSystemField,
  handleToggle,
  handleNameChange,
  handleDescChange,
}) => {
  const isAdministrator = useSelector((state) => state.settings.isAdministrator);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          p: '10px',
          height: 60,
          borderRadius: 1,
          bgcolor: '#f0f0f0',
          borderBottom: 'solid 1px #e0e0e0',
          cursor: 'pointer',
          backgroundColor: localStorage.getItem('theme') === 'DARK' ? '#282828' : '#F3F3F3',
        }}
      >
        {editFieldId === tool.id ? (
          <TextField
            fullWidth
            variant="standard"
            value={editFieldId === tool.id ? editedTitle : tool?.name}
            sx={{
              paddingLeft: 3.5,
              border: 'none',
              input: { cursor: 'pointer' },
              '& .MuiInputBase-input': {
                textOverflow: 'ellipsis',
              },
            }}
            InputProps={{
              disableUnderline: true,
            }}
            inputRef={(input) => input && input.focus()}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
        ) : (
          <>
            <IconButton
              size="small"
              onClick={() =>
                (tool.has_description || tool.has_system_prompt) &&
                setSystemField((value) => (value === tool.id ? null : tool.id))
              }
              sx={{ opacity: tool.has_description || tool.has_system_prompt ? 0.8 : 0, width: 20, height: 20 }}
            >
              {systemField === tool.id ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
            </IconButton>
            <Typography
              variant="body1"
              sx={{
                color: localStorage.getItem('theme') === 'DARK' ? '#F3F3F3' : '#7e7e7e',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              onClick={() => tool.has_description && setSystemField((value) => (value === tool.id ? null : tool.id))}
            >
              {tool?.name}
            </Typography>
          </>
        )}
        {!tool.is_editable ? (
          <Switch checked={!tool.is_disabled} onChange={() => handleToggle(tool.id, tool.is_disabled)} />
        ) : editFieldId === tool.id ? (
          <>
            {btnLoader ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size="18px" />
              </Box>
            ) : (
              <>
                <IconButton onClick={() => setEditFieldId(null)}>
                  <CloseIcon />
                </IconButton>
                <IconButton onClick={() => handleNameChange(tool.id)}>
                  <CheckIcon />
                </IconButton>
              </>
            )}
          </>
        ) : (
          isAdministrator && (
            <IconButton
              onClick={() => {
                setEditFieldId(tool.id);
                setEditedTitle(tool?.name);
              }}
            >
              <EditRoundedIcon />
            </IconButton>
          )
        )}
      </Stack>
      {systemField === tool.id && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            p: '15px',
            cursor: 'pointer',
            m: 1,
          }}
        >
          {editedField?.id === tool.id ? (
            <TextField
              fullWidth
              variant="standard"
              value={editedField?.value ?? ''}
              multiline
              sx={{
                border: 'none',
                input: { cursor: 'pointer' },
                '& .MuiInputBase-input': {
                  textOverflow: 'ellipsis',
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              inputRef={(input) => input && input.focus()}
              onChange={(e) => setEditedField((prev) => ({ ...prev, value: e.target.value }))}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: '#7e7e7e',
                width: '100%',
                whiteSpace: 'pre-line',
              }}
              onClick={() => setSystemField(null)}
            >
              {tool.has_system_prompt ? tool.system_prompt : tool.description}
            </Typography>
          )}
          {editedField?.id === tool.id ? (
            <>
              {btnLoader ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress size="18px" />
                </Box>
              ) : (
                <>
                  <IconButton onClick={() => setEditedField(null)}>
                    <CloseIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDescChange(tool.id)}>
                    <CheckIcon />
                  </IconButton>
                </>
              )}
            </>
          ) : (
            <IconButton
              onClick={() => {
                setEditedField({
                  id: tool.id,
                  value: tool.has_system_prompt ? tool.system_prompt : tool.description,
                  key: tool.has_system_prompt ? 'system_prompt' : 'description',
                });
              }}
            >
              {isAdministrator && <EditRoundedIcon />}
            </IconButton>
          )}
        </Stack>
      )}
    </>
  );
};

export default SystemTools;
