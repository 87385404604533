import { combineReducers, configureStore } from '@reduxjs/toolkit';
import SettingsReducer from './slices/SettingsSlice';
import AlertsReducer from './slices/AlertsSlice';
import MasterReducer from './slices/MasterSlice';

const rootReducer = combineReducers({
  settings: SettingsReducer,
  Alerts: AlertsReducer,
  Master: MasterReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
