import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CancelIcon, CheckCircleRoundedIcon } from '../../mui';

const NotificationAlerts = () => {
  const snackBarAlert = useSelector((state) => state.Alerts.snackBarAlert);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const actionRenderer = useCallback(
    (key, severity) => {
      if (severity === 'success') {
        return <CheckCircleRoundedIcon onClick={() => closeSnackbar(key)} sx={{ cursor: 'pointer' }} />;
      }
      return <CancelIcon onClick={() => closeSnackbar(key)} sx={{ cursor: 'pointer' }} />;
    },
    [closeSnackbar]
  );

  useEffect(() => {
    if (snackBarAlert) {
      snackBarAlert.message.map((msg) =>
        enqueueSnackbar(msg, {
          variant: snackBarAlert.severity,
          action: (key) => actionRenderer(key, snackBarAlert.severity),
        })
      );
    }
  }, [snackBarAlert, actionRenderer, enqueueSnackbar]);
};

export default memo(NotificationAlerts);
