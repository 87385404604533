import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, Stack, TextField, Typography } from '../mui';
import { setSettingsRerender } from '../redux/slices/MasterSlice';
import { createCustomCategory } from './apiCalls';

const CustomCategoryDialog = ({ openDialog, setOpenDialog }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCategoryCreate = async () => {
    setLoader(true);
    const payload = {
      name,
      description,
    };

    const response = await dispatch(createCustomCategory(payload));

    if (response) {
      handleClose();
      // dispatch(setActiveRerender());
      dispatch(setSettingsRerender());
    }
    setLoader(false);
  };

  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="md" PaperProps={{ style: { minHeight: 520 } }}>
      <DialogContent>
        {!loader ? (
          <Box>
            <Typography variant="h4">Add Category Information</Typography>
            <Grid container justifyContent="center" alignItems="center" spacing={2} my={3}>
              <Grid item xs={10}>
                <TextField
                  value={name ?? ''}
                  onChange={(e) => setName(e.target.value.trimStart())}
                  label="Title"
                  fullWidth
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={description ?? ''}
                  onChange={(e) => setDescription(e.target.value.trimStart())}
                  label="Description"
                  fullWidth
                  multiline
                  rows={5}
                />
              </Grid>

              <Grid item xs={10}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2} columnSpacing={4}>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                      <Button variant="contained" disabled={!(name && description)} onClick={handleCategoryCreate}>
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" my={20}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomCategoryDialog;
