import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { IconButton, Slider } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import HtmlTooltip from '../../components/tooltip';
import { Box, Grid, Typography } from '../../mui';
import { promptTempPlaceholders, promptTempToolTip, promtToppPlaceholders } from '../../utils/constants';

const AdvancedTab = ({ formik }) => (
  <>
    <Box mt={8}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={8} columnSpacing={4}>
        <Grid item xs={4}>
          <Typography>Temperature:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Slider
            id="temperature"
            name="temperature"
            value={formik.values.temperature}
            valueLabelDisplay="auto"
            step={0.1}
            min={0}
            max={2}
            marks={promptTempPlaceholders}
            onChange={(e) => formik.setFieldValue('temperature', e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <HtmlTooltip TransitionComponent={Zoom} title={promptTempToolTip}>
            <IconButton size="small" sx={{ opacity: 0.48, border: '1px solid grey' }}>
              <QuestionMarkIcon sx={{ fontSize: '15px' }} />
            </IconButton>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={4}>
          <Typography>Top_p:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Slider
            id="topP"
            name="topP"
            value={formik.values.topP}
            valueLabelDisplay="auto"
            step={0.1}
            min={0}
            max={1}
            marks={promtToppPlaceholders}
            onChange={(e) => formik.setFieldValue('topP', e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <HtmlTooltip TransitionComponent={Zoom} title={promptTempToolTip}>
            <IconButton size="small" sx={{ opacity: 0.48, border: '1px solid grey' }}>
              <QuestionMarkIcon sx={{ fontSize: '15px' }} />
            </IconButton>
          </HtmlTooltip>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default AdvancedTab;
