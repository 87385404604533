import { useSelector } from 'react-redux';
import { Box, CheckIcon, CircularProgress, CloseIcon, DeleteIcon, EditRoundedIcon, Stack, Typography } from '../../mui';

const CustomTools = ({
  tool,
  hoverFieldId,
  dltBtnLoader,
  editPersonality,
  deletePersonality,
  selectedField,
  setSelectedField,
  setHoverFieldId,
}) => {
  const isAdministrator = useSelector((state) => state.settings.isAdministrator);
  return (
    <Box key={tool.id}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        onMouseEnter={() => setHoverFieldId(tool.id)}
        onMouseLeave={() => setHoverFieldId(null)}
        sx={{
          p: '15px',
          borderRadius: 1,
          bgcolor: hoverFieldId === tool.id ? '#7e7e7e' : '#f0f0f0',
          borderBottom: 'solid 1px #e0e0e0',
          cursor: 'pointer',
          backgroundColor: localStorage.getItem('theme') === 'DARK' ? '#282828' : '#F3F3F3',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: 3.5,
            color: localStorage.getItem('theme') === 'DARK' && hoverFieldId !== tool.id ? '#F3F3F3' : '#7e7e7e',
          }}
          onClick={() =>
            setSelectedField((prev) => (prev?.id === tool.id ? { ...prev, id: '' } : { ...prev, id: tool.id }))
          }
        >
          {tool.name}
        </Typography>
        {isAdministrator
          ? (hoverFieldId === tool.id || selectedField?.deleteId === tool.id) && (
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                {selectedField?.deleteId !== tool.id ? (
                  <>
                    <EditRoundedIcon
                      sx={{
                        color: '#7e7e7e',
                        fontSize: 18,
                        cursor: 'pointer',
                      }}
                      onClick={(e) => editPersonality(e, tool.id)}
                    />
                    <DeleteIcon
                      sx={{
                        color: '#7e7e7e',
                        fontSize: 18,
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedField((value) => ({ ...value, deleteId: tool.id }))}
                    />
                  </>
                ) : (
                  <>
                    <CloseIcon
                      sx={{
                        color: 'red',
                        fontSize: 18,
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedField((prev) => ({ ...prev, deleteId: '' }))}
                    />
                    {dltBtnLoader ? (
                      <Box display="flex" justifyContent="center" alignItems="center" m={0.8}>
                        <CircularProgress size="18px" />
                      </Box>
                    ) : (
                      <CheckIcon
                        sx={{
                          fontSize: 18,
                          cursor: 'pointer',
                        }}
                        onClick={(e) => deletePersonality(e, tool.id)}
                      />
                    )}
                  </>
                )}
              </Stack>
            )
          : null}
      </Stack>
      {selectedField?.id === tool.id && (
        <Box
          onClick={() => setSelectedField(null)}
          sx={{
            p: '15px',
            cursor: 'pointer',
            m: 1,
          }}
        >
          <Typography
            component="span"
            variant="body2"
            sx={{
              width: '100%',
              fontWeight: 'bold',
              paddingRight: 3,
              color: localStorage.getItem('theme') === 'DARK' ? '#F3F3F3' : '#7e7e7e',
            }}
          >
            Category :
          </Typography>
          <Typography
            component="span"
            variant="body2"
            sx={{
              color: localStorage.getItem('theme') === 'DARK' ? '#F3F3F3' : '#7e7e7e',
              width: '100%',
            }}
          >
            {tool.custom_tool_category}
          </Typography>
          <br />
          <Typography
            component="span"
            variant="body2"
            sx={{
              color: localStorage.getItem('theme') === 'DARK' ? '#F3F3F3' : '#7e7e7e',
              width: '100%',
              fontWeight: 'bold',
              paddingRight: 1.1,
            }}
          >
            Description :
          </Typography>
          <Typography
            component="span"
            variant="body2"
            sx={{
              color: localStorage.getItem('theme') === 'DARK' ? '#F3F3F3' : '#7e7e7e',
              width: '100%',
            }}
          >
            {tool.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomTools;
