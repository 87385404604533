import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import userFlow from 'userflow.js';
import { setAdministrator } from '../../redux/slices/SettingsSlice';
import axios from '../../utils/axios';
import Header from './header';
import Nav from './nav';

const APP_BAR_MOBILE = 64;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 40,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout({ handleThemeChange, isDarkMode, textSize, setTextSize }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const userID = localStorage.getItem('userID');
    if (accessToken && userID) {
      (async () => {
        try {
          const { data: response } = await axios.get(`/users/${userID}`);
          if (response.status) {
            const responseData = response?.data;
            dispatch(setAdministrator(responseData?.groups));
            await userFlow.identify(responseData?.sub_id, {
              name: `${responseData.first_name} ${responseData.last_name}`,
              email: responseData.email,
              signed_up_at: moment(new Date()).toISOString(),
            });
            userFlow.start('ct_eljj44mgpfblhbqxlq4tuyv2lq');
          }
        } catch (err) {
          console.log('err', err);
        }
      })();
    }
  }, [dispatch]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav
        openNav={open}
        onCloseNav={() => setOpen(false)}
        handleThemeChange={handleThemeChange}
        isDarkMode={isDarkMode}
        textSize={textSize}
        setTextSize={setTextSize}
      />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
