import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: null,
  isAdministrator: false,
};

export const SettingsSlice = createSlice({
  name: 'SettingsSlice',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setAdministrator: (state, action) => {
 state.isAdministrator = action.payload?.some((group) => group?.Name === 'Administrators');
    },
  },
});

export const { setSettings, setAdministrator } = SettingsSlice.actions;

export default SettingsSlice.reducer;
