import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, handleThemeChange }) {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const theme = localStorage.getItem('theme');

  useEffect(() => {
    if (!accessToken) {
      localStorage.clear();
      navigate('/login');
    }
    if (!theme) {
      handleThemeChange(false);
    } else if (theme === 'Dark') {
      handleThemeChange(true);
    } else {
      handleThemeChange(false);
    }
  }, [navigate, accessToken, handleThemeChange, theme]);

  return accessToken ? children : null;
}

export default AuthGuard;
