import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { IconButton, InputLabel, Stack } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import HtmlTooltip from '../../components/tooltip';
import FilePicker from '../../components/file-picker/FilePicker';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from '../../mui';
import { promptModel, promptModelToolTip } from '../../utils/constants';

const GeneralTab = ({ formik, customToolCategory }) => (
  <Box>
    <Grid container justifyContent="center" alignItems="start" spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={formik.values.name}
          onChange={(e) => formik.setFieldValue('name', e.target.value.trimStart())}
          label="Name"
          id="name"
          name="name"
          fullWidth
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={formik.touched.selectedCategory && Boolean(formik.errors.selectedCategory)}>
          <InputLabel htmlFor="my-input">Select Category*</InputLabel>
          <Select
            id="selectedCategory"
            name="selectedCategory"
            label="Select Category*"
            value={customToolCategory?.length ? formik.values.selectedCategory : ''}
            onChange={(e) => formik.setFieldValue('selectedCategory', e.target.value)}
          >
            <MenuItem disabled>Select Category*</MenuItem>
            {customToolCategory.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.selectedCategory && formik.errors.selectedCategory}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="description"
          name="description"
          value={formik.values.description}
          onChange={(e) => formik.setFieldValue('description', e.target.value.trimStart())}
          label="Assistant Description"
          fullWidth
          multiline
          rows={5}
          error={formik.touched.description && Boolean(formik.errors.description)}
          helperText={formik.touched.description && formik.errors.description}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="customPrompt"
          name="customPrompt"
          value={formik.values.customPrompt}
          onChange={(e) => formik.setFieldValue('customPrompt', e.target.value.trimStart())}
          label="Assistant Guidance"
          fullWidth
          multiline
          rows={7}
          error={formik.touched.customPrompt && Boolean(formik.errors.customPrompt)}
          helperText={formik.touched.customPrompt && formik.errors.customPrompt}
        />
      </Grid>

      <Grid item xs={6}>
        <FilePicker
          file={formik.values.avatar?.file ?? formik.values.avatar}
          setFile={(e) => formik.setFieldValue('avatar', { file: e })}
          handleRemoveFile={() => formik.setFieldValue('avatar', null)}
          title="Avatar"
          dimensions="400 X 400"
        />
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography>Model:</Typography>

              <FormControl fullWidth size="small">
                <Select
                  id="model"
                  name="model"
                  value={formik.values.model}
                  onChange={(e) => formik.setFieldValue('model', e.target.value)}
                >
                  {promptModel.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <HtmlTooltip TransitionComponent={Zoom} title={promptModelToolTip}>
                <IconButton size="small" sx={{ opacity: 0.48, border: '1px solid grey' }}>
                  <QuestionMarkIcon sx={{ fontSize: '15px' }} />
                </IconButton>
              </HtmlTooltip>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Switch
                    checked={formik.values.knowledgebase}
                    onChange={(e) => formik.setFieldValue('knowledgebase', e.target.checked)}
                  />
                }
                label="Knowledgebase"
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default GeneralTab;
