import { memo } from 'react';
import { Box, Grid, Typography } from '../../mui';

const ToolsTab = () => (
  <Grid container justifyContent="center" alignItems="center" direction={'column'} spacing={6}>
    <Grid item xs={12}>
      <Typography variant="subtitle2" color="text.secondary">
        Supercharge your AI Assistants by giving them cutting edge tools and abilities
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Box
        component="img"
        sx={{
          maxHeight: 500,
          maxWidth: 500,
        }}
        alt="Comming Soon"
        src="/assets/ComingSoon.png"
      />
    </Grid>
  </Grid>
);

export default memo(ToolsTab);
