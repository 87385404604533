// import { useState } from 'react';
import PropTypes from 'prop-types';
// import InviteDialog from '../../../pages/InviteDialog';
// @mui
import { AppBar, Toolbar, IconButton, styled, Grid } from '../../../mui';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import DropButton from '../../../components/dropButton';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 40;

const HEADER_DESKTOP = 50;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(2, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={12}>
            <IconButton
              onClick={onOpenNav}
              sx={{
                mt: 3,
                color: 'text.primary',
                display: { lg: 'none' },
              }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
            <DropButton />
            {/* <Button
              variant="contained"
              endIcon={<PersonAddAltRoundedIcon />}
              sx={{ float: 'right', minWidth: 150, mt: 2, boxShadow: 0 }}
              onClick={() => setOpenDialog(true)}
            >
              Invite
            </Button> */}
          </Grid>
        </Grid>
      </StyledToolbar>
    </StyledRoot>
  );
}
