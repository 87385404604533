import { forwardRef, useState } from 'react';
import { Stack, Snackbar, MuiAlert } from '../../mui';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function CustomizedSnackbars({ severity, message }) {
  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity || 'success'} sx={{ width: '100%' }}>
          {message ?? ''}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
