import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LoadingButton,
  Stack,
  Tab,
  Typography,
} from '../mui';
import { setSelectedPrompt, setSettingsRerender } from '../redux/slices/MasterSlice';
import { promptModel } from '../utils/constants';
import {
  createCustomTool,
  getCategoryDropdown,
  getCustomToolById,
  getCustomToolCategory,
  updateCustomTool,
  uploadFileAvatar,
} from './apiCalls';
import AdvancedTab from './customToolDilogTabs/AdvancedTab';
import GeneralTab from './customToolDilogTabs/GeneralTab';
import KnowledgeTab from './customToolDilogTabs/KnowledgeTab';
import ToolsTab from './customToolDilogTabs/ToolsTab';

const CustomToolDialogue = ({ openDialog, setOpenDialog, category, customToolId, setCustomToolId }) => {
  const [tabValue, setTabValue] = useState('1');
  const [categoryList, setCategoryList] = useState([]);
  const [customToolData, setCustomToolData] = useState(null);
  const [customToolCategory, setCustomToolCategory] = useState([]);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();
  const search = JSON.parse(localStorage.getItem('search'));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    setCustomToolId(null);
    setOpenDialog(false);
  };

  const handlePromptCreate = async (values, setSubmitting) => {
    const {
      name,
      description,
      customPrompt,
      model,
      selectedCategory,
      temperature,
      topP,
      categoriesIds,
      selectAllCategories,
      avatar,
      knowledgebase,
    } = values;

    try {
      const selectedTreeCategories = categoryList
        .filter((item) => categoriesIds?.includes(item.id))
        .map((item) => ({
          ...item,
          sub_categories: (item.sub_categories || []).filter((subCategory) => categoriesIds?.includes(subCategory.id)),
        }));

      let avatarId = null;

      if (avatar?.file) {
        const formData = new FormData();
        formData.append('file', avatar.file.image);
        formData.append('media_type', 'ICON');
        avatarId = await dispatch(uploadFileAvatar(formData));
      } else if (avatar?.id) {
        avatarId = avatar.id;
      }

      let payload = {
        name,
        description,
        system_prompt: customPrompt,
        model_name: model,
        custom_tools_category_id: selectedCategory,
        model_temperature: temperature,
        model_top_p: topP,
        use_knowledge_base: knowledgebase,
        select_all_categories: selectAllCategories,
        categories: selectedTreeCategories,
        icon_media_id: avatarId,
      };

      if (!knowledgebase) {
        payload = { ...payload, select_all_categories: false, categories: [] };
      }

      console.log(payload);
      let response = null;
      if (customToolId) {
        response = await dispatch(updateCustomTool(customToolId, payload));
        if (response && search.id === customToolId) {
          dispatch(setSelectedPrompt(null));
        }
      } else {
        response = await dispatch(createCustomTool(payload));
      }
      if (response) {
        handleClose();
        dispatch(setSettingsRerender());
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    customPrompt: Yup.string().required('Custom prompt is required'),
    model: Yup.string().required('Model is required'),
    selectedCategory: Yup.string().required('Select a category'),
    temperature: Yup.number(),
    topP: Yup.number(),
    selectAllCategories: Yup.boolean(),
    knowledgebase: Yup.boolean(),
    categoriesIds: Yup.array().when(['selectAllCategories', 'knowledgebase'], {
      is: (selectAllCategories, knowledgebase) => !selectAllCategories && knowledgebase,
      then: () => Yup.array().min(1, 'Select at least one category').required('Required'),
      otherwise: () => Yup.array(),
    }),
    avatar: Yup.mixed().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      name: customToolData ? customToolData?.name : '',
      description: customToolData ? customToolData?.description : '',
      customPrompt: customToolData ? customToolData?.customPrompt : '',
      model: customToolData ? customToolData?.model : promptModel[0]?.value,
      selectedCategory: customToolData ? customToolData?.selectedCategory : category,
      temperature: customToolData ? customToolData?.temperature : 0.6,
      topP: customToolData ? customToolData?.topP : 0.4,
      selectAllCategories: customToolData ? customToolData?.selectAllCategories : true,
      categoriesIds: customToolData ? customToolData?.categoriesIds : [],
      avatar: customToolData ? customToolData.iconData : null,
      knowledgebase: customToolData ? customToolData.knowledgebase : true,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => handlePromptCreate(values, setSubmitting),
  });

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const responseData = await dispatch(getCategoryDropdown(controller.signal));
      if (responseData) setCategoryList(responseData);
    })();
    return () => controller.abort();
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const responseData = await dispatch(getCustomToolCategory(controller.signal));
      if (responseData) setCustomToolCategory(responseData);
    })();
    return () => controller.abort();
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      if (customToolId) {
        const responseData = await dispatch(getCustomToolById(customToolId, controller.signal));
        if (responseData) setCustomToolData(responseData);
      }
      setLoader(false);
    })();
    return () => controller.abort();
  }, [dispatch, customToolId]);

  return (
    <Dialog
      component="form"
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { height: 620 } }}
      onSubmit={formik.handleSubmit}
    >
      {!loader ? (
        <>
          <DialogContent>
            <Stack flex direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4">Create New Assistant</Typography>
            </Stack>
            <Box sx={{ width: '100%', mt: 1 }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange}>
                    <Tab label="General" value="1" />
                    <Tab label="Knowledge" value="2" disabled={!formik.values.knowledgebase} />
                    <Tab label="Tools" value="3" />
                    <Tab label="Advanced" value="4" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <GeneralTab formik={formik} customToolCategory={customToolCategory} />
                </TabPanel>
                <TabPanel value="2">
                  <KnowledgeTab categoryList={categoryList} formik={formik} />
                </TabPanel>
                <TabPanel value="3">
                  <ToolsTab />
                </TabPanel>
                <TabPanel value="4">
                  <AdvancedTab formik={formik} />
                </TabPanel>
              </TabContext>
            </Box>
          </DialogContent>
          <DialogActions sx={{ position: 'relative' }}>
            {formik.touched.categoriesIds && formik.errors?.[Object.keys(formik.errors)[0]] ? (
              <Alert
                sx={{
                  position: 'absolute',
                  bottom: 10,
                  left: 10,
                }}
                severity="error"
              >
                {formik.errors[Object.keys(formik.errors)[0]]}
              </Alert>
            ) : null}
            <LoadingButton loading={formik.isSubmitting} variant="contained" type="submit">
              Save
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <Box display="flex" justifyContent="center" my={20}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default CustomToolDialogue;
