import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { useCallback, useEffect } from 'react';
import CustomTreeItem from './MuiCustomTree';

const KnowledgeTab = ({ categoryList, formik }) => {
  const { values, setFieldValue } = formik;

  const getAllCategoryIds = useCallback(
    (categories) =>
      categories.reduce((allIds, category) => {
        allIds.push(category.id);
        if (category.sub_categories) {
          allIds = allIds.concat(getAllCategoryIds(category.sub_categories));
        }
        return allIds;
      }, []),
    []
  );

  const handleCategoryToggle = (category, subCategories) => {
    const isSelected = values.categoriesIds.includes(category.id);
    let updatedSelectedCategoriesIds;

    if (isSelected) {
      updatedSelectedCategoriesIds = values.categoriesIds.filter((id) => id !== category.id);
    } else if (category.level === 1) {
      updatedSelectedCategoriesIds = [...new Set([...values.categoriesIds, category.id, category.parentId])];
    } else {
      updatedSelectedCategoriesIds = [...values.categoriesIds, category.id];
    }

    if (subCategories) {
      const subCategoryIds = getAllCategoryIds(subCategories);
      updatedSelectedCategoriesIds = isSelected
        ? updatedSelectedCategoriesIds.filter((id) => !subCategoryIds.includes(id))
        : [...updatedSelectedCategoriesIds, ...subCategoryIds];
    }
    setFieldValue('categoriesIds', updatedSelectedCategoriesIds);
    setFieldValue(
      'selectAllCategories',
      updatedSelectedCategoriesIds.length === getAllCategoryIds(categoryList).length
    );
  };

  const handleSelectAllToggle = (e, value) => {
    if (value) {
      setFieldValue('selectAllCategories', true);
    } else {
      setFieldValue('selectAllCategories', false);
      setFieldValue('categoriesIds', []);
    }
  };

  useEffect(() => {
    if (values.selectAllCategories) {
      setFieldValue('categoriesIds', getAllCategoryIds(categoryList));
    }
  }, [getAllCategoryIds, setFieldValue, values.selectAllCategories, categoryList]);

  const renderCategory = (category) => (
    <CustomTreeItem
      key={category.id}
      nodeId={category.id.toString()}
      label={
        <div style={{ padding: '6px 0', display: 'flex', alignItems: 'center', gap: '6px' }}>
          <Checkbox
            id="categories"
            name="categoriesIds"
            size="small"
            style={{ width: '15px', height: '15px', padding: '15px' }}
            checked={
              values?.selectAllCategories ? values.selectAllCategories : values.categoriesIds.includes(category.id)
            }
            onChange={() => handleCategoryToggle(category, category.sub_categories)}
            onBlur={formik.handleBlur}
          />
          <span>{category.category}</span>
        </div>
      }
    >
      {category.sub_categories && category.sub_categories.map(renderCategory)}
    </CustomTreeItem>
  );

  return (
    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {/* {!values.categoriesIds?.length && <Alert severity="error">Select at least one category</Alert>} */}
      <CustomTreeItem
        nodeId="select-all"
        label={
          <div style={{ padding: '10px 0', display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Checkbox
              size="small"
              style={{ width: '15px', height: '15px' }}
              checked={values.selectAllCategories}
              onChange={handleSelectAllToggle}
            />
            <span>All Categories</span>
          </div>
        }
      />
      {categoryList.map(renderCategory)}
    </TreeView>
  );
};

export default KnowledgeTab;
